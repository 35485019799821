import ModernBanner from "../../components/banner/ModernBanner";
import { bookstore_features } from "../../helpers/bookstore-features";
import Overview from "../../components/overview/Overview";
import ProjectFeatures from "../../components/project-features/ProjectFeatures";
import Steps from "../../components/steps/Steps";

type Props = {};

function BookStore(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ModernBanner title="Book" span="Store" imgSrc="/bookstore2.png" imgWidth='90%' />
      <Overview overview={bookstore_features.overview} />
      <ProjectFeatures list={bookstore_features.items} />

      <Steps steps={bookstore_features.steps} title="Technical" span="Stack" />
    </div>
  );
}

export default BookStore;
