export const collapse = [
    {
        title: 'Custom Software Development',
        paragraph: 'From the ideation stage to the final deployment, our expert team is here to offer you unparalleled software solutions, be it CRMs, financial tools, or personalized software tailored to your needs.'
    },
    {
        title: 'Technical Partnership',
        paragraph: 'Lack an in-house tech team? We step in as your dedicated technical partner, ensuring your business has the technological backbone it deserves.'
    },
    {
        title: 'Smart Money Investments',
        paragraph: 'We don\'t just fund startups. We invest our vast SaaS business knowledge, offering mentorship and guidance every step of the way.'
    }
]