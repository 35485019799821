import { useRef, useState } from "react";
import { useEffect } from "react";
import "./style.css";
import useOnScreen from "../../helpers/isIntersectingHook";

type Props = {
  steps: any;
  title?: string;
  span?: string;
};

function ThreeSteps(props: Props) {
  const missionLine: any = useRef();

  const isVisableMissionLine = useOnScreen(missionLine);

  useEffect(() => {
    const handleScroll = () => {
      missionLine.current.style.opacity = 1;
      missionLine.current.style.width = 90 + "%";
    };

    if (isVisableMissionLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableMissionLine]);

  return (
    <div className="container-asdasd">
      <h2 className="heading h2">
        {props.title} <span className="headingSpan">{props.span}</span>
      </h2>
      <div className="line" ref={missionLine}>
      <br />
      <div className="container-steps-three">
        {props.steps.map(
          (v: { title: string; paragraph: string; circleType: string }) => (
            <div className="item-s">
              <div className={v.circleType} />
              <div>
                <div className="title-steps">{v.title}</div>
                <div>{v.paragraph}</div>
              </div>
            </div>
          )
        )}
      </div>
      </div>
    </div>
  );
}

export default ThreeSteps;
