import React from "react";
import { useRef } from "react";
import "./wrapperStyle.css";
import useOnScreen from "../helpers/isIntersectingHook";
import { useEffect } from "react";

type Props = {
  children: React.ReactNode;
  title?: string;
  span?: string;
  right?: boolean
};

function LineWrapper(props: Props) {
  const getInTouch: any = useRef();
  const isVisablegetInTouch = useOnScreen(getInTouch);

  useEffect(() => {
    const handleScroll = () => {
      getInTouch.current.style.opacity = 1;

      getInTouch.current.style.width = 90 + "%";
    };

    if (isVisablegetInTouch) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisablegetInTouch]);

  return (
    <div style={{width: '100%'}}>
      <h2 className="heading h2">
        {props.title} <span className="headingSpan">{props.span}</span>
      </h2>

      <div className={props.right ? "line" : "lineRight"} ref={getInTouch} />
      {props.children}
    </div>
  );
}

export default LineWrapper;
