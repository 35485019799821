import ModernBanner from "../../components/banner/ModernBanner";
import { scheduler_features } from "../../helpers/scheduler-features";
import Overview from "../../components/overview/Overview";
import ProjectFeatures from "../../components/project-features/ProjectFeatures";
import Summary from "../../components/summary/Summary";
import Steps from "../../components/steps/Steps";
import ThreeSteps from "../../components/steps/ThreeSteps";

type Props = {};

function Scheduler(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ModernBanner
        title="Patient Appointment"
        span="Scheduler"
        imgSrc="/scheduler2.png"
      />
      <Overview overview={scheduler_features.overview} />
      <ProjectFeatures list={scheduler_features.items} />

      <ThreeSteps steps={scheduler_features.steps} title="Technical" span="Stack"/>

      <Summary summary={scheduler_features.summary}/>
    </div>
  );
}

export default Scheduler;
