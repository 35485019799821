export const bookstore_features = {
  overview: `An intelligent system to gather vast amounts of data about book availability from suppliers and employ it to create the most cost-effective offers for customer requests.`,
  items: [
    {
      title: "Pricing Algorithms",
      paragraph:
        "Advanced pricing algorithms factoring in various parameters and customer buying patterns.",
    },
    {
      title: "Negotiation Tools",
      paragraph: "Enables real-time price negotiations with suppliers.",
    },
    {
      title: "Integrated Ordering",
      paragraph:
        "Creates purchase orders based on negotiated prices, ensuring inventory is sourced at the lowest possible cost.",
    },
  ],
  steps: [
    {
      title: "Frontend",
      circleType: 'full',
      paragraph: "ReactJS",
    },
    {
      title: "Backend",
      circleType: 'empty',
      paragraph: "NodeJS + Express",
    },
    {
      title: "Other",
      circleType: 'empty',
      paragraph: "Login/Register system, API for currency exchange rate updates, data import/export (handling up to 1,000, 000 excel rows), dynamic offer creation based on client feedback, comprehensive inventory catalog, and deployment on AWS",
    },
    {
      title: "Database",
      circleType: 'empty',
      paragraph: "MySQL",
    },
  ],
};
