import LineWrapper from "../../wrappers/LineWrapper";
import "./style.css";

type Props = {
  summary: string;
  tools?: any;
};

function Summary(props: Props) {
  return (
    <div className="container-s">
      {props.tools ? (
        <>
          <LineWrapper title="Internal" span="Tools">
            {props.tools.map((item: any) => (
              <div className="gap collapseContainer">
                <div className="collapseContainerinner">
                  <div className="emptyCircleContainer">
                    <div className="emptyCircle" />
                  </div>
                  <div className="collapse">
                    <h3 className="h3">{item.title}</h3>
                    <div className="smallp">{item.paragraph}</div>
                  </div>
                </div>
              </div>
            ))}
            <br />
            <br />
            <div className="text-s">{props.summary}</div>
          </LineWrapper>
        </>
      ) : (
        <div className="container-summary">
          <div className="text-s">{props.summary}</div>
        </div>
      )}
      <br />
    </div>
  );
}

export default Summary;
