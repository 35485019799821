import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export const contactInformation = [
    {
        name: 'Phone',
        icon: <LocalPhoneOutlinedIcon sx={{fontSize:'25px'}}/>,
        details: '+389 78333394'
    },
    {
        name: 'E-MAIL',
        icon: <EmailOutlinedIcon sx={{fontSize:'25px'}}/>,
        details: 'info@tgn.mk'
    },
    {
        name: 'ADDRESS',
        icon: <LocationOnOutlinedIcon sx={{fontSize:'25px'}}/>,
        details: 'Borka Talevski 12b, Centar, Skopski 1114, MK'
    }
]