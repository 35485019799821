export const footerLinks = [
    {
        link:'#about',
        name: 'About',
        links: [
            {
                name: 'Our Mission',
                link: '#about'
            },
            {
                name: 'Our Vision',
                link: '#about'
            },
            {
                name: 'Milestones',
                link: '#about'
            }
        ]
    },
    {
        name:'Services',
        link: '#services',
        links: [
            {
                name: 'Custom Software Development',
                link: '#services'
            },
            {
                name: 'Technical Partnership',
                link: '#services'
            },
            {
                name: 'Smart Money Investnets',
                link: '#services'
            }
        ]
    },
    {
        name:'Projects',
        link: '#projects',
        links: [
            {
                name: 'Target Group CRM',
                link: '#projects'
            },
            {
                name: 'Medical Patient Portal',
                link: '#projects'
            },
            {
                name: 'Custom Offer Generator for \n' +
                    'Book Wholesalers',
                link: '#projects'
            },
            {
                name: 'MoveCargo',
                link: '#projects'
            },
            {
                name: 'Modeliks',
                link: '#projects'
            }
        ]
    },
    {
        name:'Why TGN Solutions',
        link: '#whytgn',
        links: [
        ]
    },
    {
        name:'Our Team',
        link: '#ourteam',
        links: [
        ]
    },

]