export const homepage_steps = [
    {
        title: 'Our Mission',
        circleType: 'full',
        paragraph: 'Craft custom software solutions, addressing complex challenges with innovative software tailored to individual needs, timelines, and budgets. Our relentless commitment to quality goes the extra mile, treating each project with unparalleled dedication, regardless of size. We nurture our talented workforce and ensure our clients experience excellence.'
    },
    {
        title: 'Our Vision',
        circleType: 'empty',
        paragraph: 'We envision becoming a global strategic partner, pioneering software innovation in medical and finance. In the next decade, TGN Solutions aspires to be an international beacon of excellence, launching products that meet evolving needs. We invest deeply in our workforce, fostering a fulfilling environment for our team, ensuring our clients have a partner genuinely looking out for their best interests.'
    },
    {
        title: 'Milestones',
        circleType: 'empty',
        paragraph: 'Our most notable achievements revolve around our contributions to the SaaS sector, our development of two innovative medical software projects, and our collaborations with promising startups.'
    }
]