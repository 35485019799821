import React from "react";
import { Box } from "@mui/material";
import { useRef, useEffect } from "react";
import useOnScreen from "../../helpers/isIntersectingHook";

type Props = {
    overview: string
};

function Overview(props: Props) {
    const journeyLine: any = useRef();
    const isVisableJourneyLine = useOnScreen(journeyLine);
  
    useEffect(() => {
        const handleScroll = () => {
          journeyLine.current.style.opacity = 1;
          journeyLine.current.style.width = 90 + "%";
        };
    
        if (isVisableJourneyLine) {
          window.addEventListener("scroll", handleScroll);
        }
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [isVisableJourneyLine]);

  return (
    <Box className="journey gap">
    <h2 className="heading h2">Overview</h2>
    <div className="line" ref={journeyLine}></div>
    <p className="p">
      {props.overview}
    </p>
  </Box>
  );
}

export default Overview;
