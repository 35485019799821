export const options:any = {
    background: {
        image: "/emile-seguin-R9OueKOtGGU-unsplash 1.png"
    },
    fullScreen:{
        enable: false,
        zIndex: 100,
    },
    particles: {

        number: {
            value: window.innerWidth > 900 ? 80 : 30,

        },
        color: {
            value: ["#238DC1", '#5BC0CE']
        },
        shape: {
            type: "circle",
            image: {
                src: "/emile-seguin-R9OueKOtGGU-unsplash 1.png",
                width: 100,
                height: 100
            }
        },
        opacity: {
            value: 0.4,

        },
        size: {
            value: { min: 5, max: 5 }
        },
        links: {
            enable: true,
            distance: 100,
            color: "#585858",
            opacity: 0.5,
            width: 1.5
        },
        move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            outModes: "out"
        }
    },
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "grab",
            },
            onClick: {
                enable: true,
                mode: "push",
                remove: {particles_nb: 2}
            }
        },
        modes: {
            grab: {
                distance: 140,
                links: {
                    opacity: 1
                },
            },
            push: {
                quantity: 2,

            },

        }
    }
};
