import Particles from "react-particles";
import { useCallback, useRef, useState } from "react";
import { loadFull } from "tsparticles";
import { options } from "../../helpers/particle options";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import "../home/Home.css";
import { footerLinks } from "../../helpers/footerLinks";
import { contactInformation } from "../../helpers/contactInformation";
import { formConfig } from "../../helpers/formConfig";
import { projects } from "../../helpers/projects";
import Contact from "../../components/contact/Contact";
import ModernBanner from "../../components/banner/ModernBanner";
import SimpleBanner from "../../components/banner/SimpleBanner";
import LineWrapper from "../../wrappers/LineWrapper";
import { HashLink } from "react-router-hash-link";

function Projects() {
  const getInTouch: any = useRef();
  const projectsLine: any = useRef();

  const [hideImg, setHideImg] = useState(0);

  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine);
  }, []);

  return (
    <div className="Home">
      <SimpleBanner title="Shaping the Future" span="of Custom Software" />
      <Box className="gap">
        <LineWrapper title="Projects" span="Portfolio">
          <div className="projectsContainer">
            <div className="projectsContainerInner">
              {projects.map((item: any) => (
                <div className="singleProject" key={item.id} onClick={() => window.scroll(0,0)}>
                  <HashLink to={`${item.link}`}>
                    <div className="singleProjectCircle"
                      // onClick={() => window.open(item.link)}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                        backgroundColor: "#2F86C1",
                        color: "white",
                      }}
                      onMouseOver={() => setHideImg(item.id)}
                      onMouseOut={() => setHideImg(0)}
                    >
                      <div>
                        {hideImg === item.id ? (
                          <b>{item.title}</b>
                        ) : (
                          <img src={item.image} alt="project" />
                        )}
                      </div>
                    </div>
                  </HashLink>
                </div>
              ))}
            </div>
          </div>
        </LineWrapper>
      </Box>

      <Contact />
    </div>
  );
}

export default Projects;
