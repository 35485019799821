import React from "react";
import { footerLinks } from "../../helpers/footerLinks";
import { contactInformation } from "../../helpers/contactInformation";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import LineWrapper from "../../wrappers/LineWrapper";
import { formConfig } from "../../helpers/formConfig";

type Props = {};

function Contact(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <LineWrapper title="Get in" span="Touch" right>
        <Box className="gap">
          <p className="getInTouchParagraph">
            Highly skilled at understanding customer’s business needs and
            offering digital solutions with high level of ingenuity, creativity
            and innovation so we provide a range of strategic, creative and
            technical solutions.
          </p>

          <div className="contactContainer">
            <div className="contactContainerInner">
              <div className="form">
                {formConfig.map((item: any) => (
                  <TextField
                    sx={{ marginBottom: "15px" }}
                    label={item.label}
                    fullWidth
                  />
                ))}
                <div className="checkBox">
                  <FormControlLabel
                    sx={{ justifyContent: "flex-start" }}
                    control={<Checkbox />}
                    label="I want to protect my data by signing an NDA"
                  />
                </div>

                <div className="buttonForm">
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <div className="info">
                <div className="infoContact">
                  {contactInformation.map((item: any) => (
                    <div className="singleContact">
                      <div className="icon">{item.icon}</div>
                      <div className="information">
                        <div className="infoName">{item.name}</div>
                        <div className="infoDetails">{item.details}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="iframe">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.122398166719!2d21.41918137650078!3d41.99764865792023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135415b5886045b9%3A0xb3b94cc9bc738466!2sTarget%20Group!5e0!3m2!1sen!2sus!4v1698668047995!5m2!1sen!2sus"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </LineWrapper>
    </div>
  );
}

export default Contact;
