export const formConfig =[
    {
        label: 'Contact Name'
    },
    {
        label: 'Street'
    },
    {
        label: 'City'
    },
    {
        label: 'Postcode'
    },
    {
        label: 'Contact Phone'
    },
    {
        label: 'E-mail'
    },
    {
        label: 'Let’s talk about your idea'
    }
]