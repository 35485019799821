import React, { Component, useState } from 'react';
import './Navbar.scss'
import MenuIcon from '@mui/icons-material/Menu';
import { links } from "../../helpers/links";
import CloseIcon from '@mui/icons-material/Close';
import { HashLink } from 'react-router-hash-link';


class Navbar extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            openMenu: false,
            openHamburger: false,
            activeTab: window.location.pathname.includes('/projects') ? 3 : 0
        }
    }

    handleHamburger = () => {
        this.setState({ openHamburger: !this.state.openHamburger })
    }

    setActiveTab = (item: any, index: number) => {

        const el = document.getElementById(item.id);

        if (el) {
            el.scrollIntoView();
        }

        switch (item.name) {
            case 'HOME':
                this.setState({ activeTab: 0, openHamburger: false })
                break;
            case 'ABOUT':
                this.setState({ activeTab: 1, openHamburger: false })
                break;
            case 'SERVICES':
                this.setState({ activeTab: 2, openHamburger: false })
                break;
            case 'PROJECTS':
                this.setState({ activeTab: 3, openHamburger: false })
                window.scroll(0,700)
                break;
            case 'WHY TGN':
                this.setState({ activeTab: 4, openHamburger: false })
                break;
            case 'OUR TEAM':
                this.setState({ activeTab: 5, openHamburger: false })
                break;
            case 'CONTACT':
                this.setState({ activeTab: 6, openHamburger: false })
                break;
            default:
                this.setState({ activeTab: 0, openHamburger: false })
                break;
        }
    }

    render() {
        return (
            <div className='navbarContainer'>
                <div className='navbarContainerInner'>
                    <div className='logoContainer'>
                        <img src='/Logo.png' />
                    </div>
                    <div className='linksContainer'>
                        {
                            links.map((item: any, index: number) => {
                                    return (
                                        <HashLink to={`/${item.link}`} className='linkContainer'>
                                            <div onClick={() => this.setActiveTab(item, index)} key={item.link} className={`tab ${index === this.state.activeTab ? 'active' : ''}`}>
                                                {item.name}
                                            </div>
                                        </HashLink>
                                    )
                                }
                            )
                        }

                        <MenuIcon onClick={this.handleHamburger} className='hamburger' />
                    </div>
                    {
                        this.state.openHamburger && <div className='mobileNavbar'>
                            <div className='hamburger-close' onClick={this.handleHamburger}><CloseIcon /></div>
                            <div className='linksContainerMob'>
                            {
                            links.map((item: any, index: number) => (
                                <HashLink to={`/${item.link}`}>
                                    <div onClick={() => this.setActiveTab(item, index)} key={item.link} className={`tab ${index === this.state.activeTab ? 'active' : ''}`}>
                                                {item.name}
                                            </div>
                                </HashLink>
                            ))
                        }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Navbar;