import ModernBanner from "../../components/banner/ModernBanner";
import { medical_features } from "../../helpers/medical_features";
import Overview from "../../components/overview/Overview";
import ProjectFeatures from "../../components/project-features/ProjectFeatures";
import Steps from "../../components/steps/Steps";

type Props = {};

function Medical(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ModernBanner
        title="Medical Patient"
        span="Portal"
        imgSrc="/MedicalPatientPortal2.png"
         imgWidth='90%'
      />
      <Overview overview={medical_features.overview} />
      <ProjectFeatures list={medical_features.items} />

      <Steps steps={medical_features.steps} title="Technical" span="Stack" />
    </div>
  );
}

export default Medical;
