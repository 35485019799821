import { useEffect, useRef, useState } from "react";
import "./Home.css";
import { Box, Button } from "@mui/material";
import useOnScreen from "../../helpers/isIntersectingHook";
import { projects } from "../../helpers/projects";
import { collapse } from "../../helpers/collapse";
import { employees } from "../../helpers/employees";
import Contact from "../../components/contact/Contact";
import SimpleBanner from "../../components/banner/SimpleBanner";
import { homepage_steps } from "../../helpers/homepage-steps";
import Steps from "../../components/steps/Steps";
import ThreeSteps from "../../components/steps/ThreeSteps";
import { HashLink } from "react-router-hash-link";
// @ts-nocheck

function Homepage() {
  const journeyLine: any = useRef();
  const serviceOfficeLine: any = useRef();
  const projectsLine: any = useRef();
  const whyChoose: any = useRef();
  const ourTeam: any = useRef();

  const [hideImg, setHideImg] = useState(0);

  const isVisableJourneyLine = useOnScreen(journeyLine);
  const isVisableserviceOfficeLine = useOnScreen(serviceOfficeLine);
  const isVisableprojectsLine = useOnScreen(projectsLine);
  const isVisablewhyChoose = useOnScreen(whyChoose);
  const isVisableourTeam = useOnScreen(ourTeam);

  useEffect(() => {
    const handleScroll = () => {
      journeyLine.current.style.opacity = 1;
      journeyLine.current.style.width = 90 + "%";
    };

    if (isVisableJourneyLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableJourneyLine]);

  useEffect(() => {
    const handleScroll = () => {
      serviceOfficeLine.current.style.opacity = 1;

      serviceOfficeLine.current.style.width = 90 + "%";
    };

    if (isVisableserviceOfficeLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableserviceOfficeLine]);

  useEffect(() => {
    const handleScroll = () => {
      projectsLine.current.style.opacity = 1;

      projectsLine.current.style.width = 90 + "%";
    };

    if (isVisableprojectsLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableprojectsLine]);

  useEffect(() => {
    const handleScroll = () => {
      whyChoose.current.style.opacity = 1;

      whyChoose.current.style.width = 90 + "%";
    };

    if (isVisablewhyChoose) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisablewhyChoose]);

  useEffect(() => {
    const handleScroll = () => {
      ourTeam.current.style.opacity = 1;

      ourTeam.current.style.width = 90 + "%";
    };

    if (isVisablewhyChoose) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableourTeam]);

  return (
    <div id="home" className="Home">
      <SimpleBanner title="Shaping the Future" span="of Custom Software" />

      <div id="about" style={{ height: 30 }}></div>

      <Box className="journey gap">
        <h2 className="heading h2" style={{ padding: "0 20px" }}>
          Unraveling the{" "}
          <span className="headingSpan">Journey of Excellence</span>
        </h2>
        <br />
        <div className="line" ref={journeyLine}></div>
        {/* <br /> */}
        <p className="p">
          Unraveling the Journey of Excellence In 2022, two eminent software
          companies, each with more than 10 years of individual expertise,
          united to form TGN Solutions. Our journey began with a focus on
          innovation and collaboration, leading us to develop state-of-the-art
          CRMs and comprehensive financial tools essential for numerous
          businesses. Alongside these achievements, we have fostered
          partnerships that have made significant contributions to the SaaS
          industry.
        </p>
      </Box>

      <ThreeSteps steps={homepage_steps} />

      <div id="services" style={{ height: 30 }}></div>
      <Box className="gap">
        <h2 className="h2">
          Our Comprehensive{" "}
          <span className="headingSpan">Service Offerings</span>
          <div className="lineRight" ref={serviceOfficeLine}></div>
          {/* <br /> */}
          {collapse.map((item: any) => (
            <div className="gap collapseContainer">
              <div className="collapseContainerinner">
                <div className="emptyCircleContainer">
                  <div className="emptyCircle" />
                </div>
                <div className="collapse">
                  <h3 className="h3">{item.title}</h3>
                  <div className="smallp">{item.paragraph}</div>
                </div>
              </div>
            </div>
          ))}
        </h2>
      </Box>

      <div id="projects" style={{ height: 30 }}></div>
      <Box className="gap">
        <h2 className="heading h2">
          Projects <span className="headingSpan">Portfolio</span>
        </h2>
        <div className="line" ref={projectsLine} />
        <div className="projectsContainer">
          <div className="projectsContainerInner">
            {projects.slice(0, 3).map((item: any) => (
              <div className="singleProject" key={item.id}>
                <HashLink to={`${item.link}`}>
                  <div
                    className="singleProjectCircle"
                    onClick={() => window.scroll(0,0)}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                      backgroundColor: "#2F86C1",
                      color: "white",
                    }}
                    onMouseOver={() => setHideImg(item.id)}
                    onMouseOut={() => setHideImg(0)}
                  >
                    <div>
                      {hideImg === item.id ? (
                        <b>{item.title}</b>
                      ) : (
                        <img src={item.image} alt="project" />
                      )}
                    </div>
                  </div>
                </HashLink>
              </div>
            ))}
          </div>
        </div>

        <HashLink to={`/projects`}>
          <div>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                fontFamily: "Montserrat",
                fontWeight: "bold",
              }}
              onClick={() => window.scroll(0, 700)}
            >
              See more
            </Button>
          </div>
        </HashLink>
      </Box>
      <div id="whytgn" style={{ height: 30 }}></div>
      <Box className="gap">
        <h2 className="heading h2">
          Why Choose <span className="headingSpan">TGN</span> Solutions?
        </h2>
        <div className="lineRight" ref={whyChoose} />
        <p className="p">
          Experience, dedication, and value-driven solutions set us apart. Our
          professionals, drawn from diverse backgrounds and boasting expertise
          in varied technologies, are committed to delivering excellence at
          every step.
        </p>
        <div id="ourteam"></div>
      </Box>

      <Box className="gap">
        <h2 className="heading h2">
          Our <span className="headingSpan">Team</span>
        </h2>
        <div className="line" ref={ourTeam} />
        <div className="employeeContainer">
          <div className="employeeContainerInner">
            {employees.slice(0, 10).map((item: any) => (
              <div className="singleEmployee">
                <a className="singleEmployeeCircle">
                  <div> {item.firstName}</div>
                  <div> {item.lastName}</div>
                  <div> {item.occupation}</div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div>
          {/* <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              fontWeight: "bold",
            }}
            onClick={() => window.open("/projects")}
          >
            See more
          </Button> */}
        </div>

        <div id="contact"></div>
      </Box>

      <Contact />
    </div>
  );
}

export default Homepage;
