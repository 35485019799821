import React from "react";

type State = {}
type Props = {}

class OurTeam extends React.Component<State, Props> {

    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>OurTeam</div>
        )
    }

}

export default OurTeam