export const crm_features = {
  overview: `A custom CRM built to optimize and automate the sales process for our clients, offering functionalities ranging from lead generation, sales monitoring, financials to customer care.`,
  items: [
    {
      title: "Lead Management",
      paragraph:
        "Automation of the sales process from lead generation to contract creation.",
    },
    {
      title: "Integration Capabilities",
      paragraph:
        "Seamless exchange of data with multiple systems for an enhanced CRM experience.",
    },
    {
      title: "Automated Reporting",
      paragraph:
        "Google Analytics integration for real-time customer engagement insights.",
    },
    {
      title: "Event Scheduling",
      paragraph:
        "Google Calendar API integration for easy event viewing and creation.",
    },
    {
      title: "Sales Performance Tracking",
      paragraph:
        "A dedicated reporting section for management to monitor sales performance.",
    },
    {
      title: "Financial Management",
      paragraph:
        "A specialized section for handling contracts and invoices, integrated with bank accounts for automatic invoice payment updates",
    },
  ],
  steps: [
    {
      title: 'Frontend',
      circleType: 'full',
      paragraph: 'ReactJS with MaterialUI template'
    },
    {
      title: 'Backend',
      circleType: 'empty',
      paragraph: 'NodeJS + Restify'
    },
    {
      title: 'Other',
      circleType: 'empty',
      paragraph: 'Google Calendar API, ChartJS, Integration with third-party software, scripts for data updates, and a robust login system'
    },
    {
      title: 'Database',
      circleType: 'empty',
      paragraph: 'SQL Server'
    },
  ],
};
