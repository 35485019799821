import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Projects from './pages/projects/Projects';
import CRM from './pages/projects/CRM';
import Cargo from './pages/projects/Cargo';
import Modelix from './pages/projects/Modelix';
import Medical from './pages/projects/Medical';
import Scheduler from './pages/projects/Scheduler';
import BookStore from './pages/projects/BookStore';
import Homepage from './pages/home/Homepage';
import Navbar from "./components/navbar/Navbar";
import OurTeam from "./pages/team/OurTeam";
import Footer from "./components/footer/Footer";

type State = {}
type Props = {}

class App extends React.Component<State, Props> {

    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <>
                <Navbar />
                <Routes>
                    <Route path='/' element={<Homepage />} />
                    <Route path='/projects' element={<Projects />} />
                    <Route path='/ourteam' element={<OurTeam />} />
                    <Route path='/projects/crm' element={<CRM />} />
                    <Route path='/projects/cargo' element={<Cargo />} />
                    <Route path='/projects/modeliks' element={<Modelix />} />
                    <Route path='/projects/medical' element={<Medical />} />
                    <Route path='/projects/scheduler' element={<Scheduler />} />
                    <Route path='/projects/bookstore' element={<BookStore />} />
                    <Route path='*' element={<Navigate replace to='/' />} />
                </Routes>
                <Footer />
            </>
        )
    }

}

export default App