import { useRef, useState } from "react";
import { useEffect } from "react";
import "./style.css";
import useOnScreen from "../../helpers/isIntersectingHook";
import LineWrapper from "../../wrappers/LineWrapper";

type Props = {
  steps: any;
  title?: string;
  span?: string;
};

function Steps(props: Props) {
  const middle = Math.ceil(props.steps.length / 2);

  const missionLine: any = useRef();
  const sidePiece: any = useRef();
  const bottomStepsLine: any = useRef();
  const stepsLine: any = useRef();

  const isVisableMissionLine = useOnScreen(missionLine);
  const isVisableSidePiece = useOnScreen(sidePiece);
  const isVisableBottomStepsLine = useOnScreen(bottomStepsLine);
  const isVisibleSideLine = useOnScreen(stepsLine);

  useEffect(() => {
    const handleScroll = () => {
      stepsLine.current.style.opacity = 1;

      stepsLine.current.style.width = 90 + "%";
    };

    if (isVisibleSideLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisibleSideLine]);

  useEffect(() => {
    const handleScroll = () => {
      missionLine.current.style.opacity = 1;
      missionLine.current.style.width = 90 + "%";
    };

    if (isVisableMissionLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableMissionLine]);

  useEffect(() => {
    const handleScroll = () => {
      setTimeout(() => {
        sidePiece.current.style.opacity = 1;
        sidePiece.current.style.width = 100 + "px";
        sidePiece.current.style.border = 1 + "px solid gray";
        sidePiece.current.style.borderLeft = "none";
      }, 500);
    };

    if (isVisableSidePiece) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableSidePiece]);

  useEffect(() => {
    const handleScroll = () => {
      setTimeout(() => {
        bottomStepsLine.current.style.opacity = 1;
        bottomStepsLine.current.style.width = 89.3 + "%";
        console.log('scrollll')
      }, 600);
    };

    if (isVisableBottomStepsLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableBottomStepsLine]);

  return (
    <div>
      <div style={{ marginBottom: "30px"}}>
        <div className="fullscreen-title-steps">
          <h2 className="heading h2">
            {props.title} <span className="headingspan">{props.span}</span>
          </h2>
        </div>

        <div className="smallscreen-title-steps">
          <h2 className="h2">
            {props.title} <span className="headingspan">{props.span}</span>
            <div className="lineRight" ref={stepsLine}></div>
          </h2>
        </div>
      </div>
      <div className="container-twoline">
        <div className="twoline" ref={missionLine}>
          <div className="container-steps">
            {props.steps
              .slice(0, middle)
              .map(
                (v: {
                  title: string;
                  paragraph: string;
                  circleType: string;
                }) => (
                  <div className="item-s">
                    <div className={v.circleType} />
                    <div>
                      <div className="title-steps">{v.title}</div>
                      <div>{v.paragraph}</div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
        <div className="side-piece" ref={sidePiece}></div>
      </div>
      <div className="bottom-steps">
        <div className="bottom-steps-line" ref={bottomStepsLine}>
          <div className="container-steps-bottom">
            {props.steps
              .slice(middle)
              .map(
                (v: {
                  title: string;
                  paragraph: string;
                  circleType: string;
                }) => (
                  <div className="item-s-b">
                    <div className={v.circleType} />
                    <div>
                      <div className="title-steps">{v.title}</div>
                      <div className="paragraph-steps">{v.paragraph}</div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;
