import React from "react";
import { footerLinks } from "../../helpers/footerLinks";
import { contactInformation } from "../../helpers/contactInformation";
import { Box } from "@mui/material";

type State = {};
type Props = {};

class Footer extends React.Component<State, Props> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ textAlign: "center"}}>
        <Box className="footer">
          <Box className="footerInner">
            <div className="footerLogo">
              <img src="/favicon.ico" height={150} width={300} alt="footer navigation" />
            </div>
            <div className="footerNavigation">
              {footerLinks.map((item: any) => (
                <div className="footerLinks">
                  <a className="footerLinkTitle" href={item.link}>
                    {item.name}
                  </a>
                  <div className="footerLinkList">
                    {item.links.map((item: any) => (
                      <a href={item.link}>{item.name}</a>
                    ))}
                  </div>
                </div>
              ))}
              <div className="footerLinks">
                <a className="footerLinkTitle" href="#contact">
                  Contact
                </a>
                <div className="footerLinkList">
                  {contactInformation.map((item: any) => (
                    <div className="singleContact">
                      <div className="icon">{item.icon}</div>
                      <div className="information">
                        <div className="infoName">{item.name}</div>
                        <div className="infoDetails">{item.details}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <div className="footerCopyright">
            *Copyright 2023. | All rights reserved.
          </div>
        </Box>
      </div>
    );
  }
}

export default Footer;
