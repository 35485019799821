import ModernBanner from "../../components/banner/ModernBanner";
import { cargo_features } from "../../helpers/cargo-features";
import Overview from "../../components/overview/Overview";
import ProjectFeatures from "../../components/project-features/ProjectFeatures";
import Summary from "../../components/summary/Summary";
import Steps from "../../components/steps/Steps";

type Props = {};

function Cargo(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ModernBanner
        title="Move"
        span="Cargo"
        imgSrc="/Cargo2.png"
         imgWidth='90%'
      />
      <Overview overview={cargo_features.overview} />
      <ProjectFeatures list={cargo_features.items} />

      <Steps steps={cargo_features.steps} title="Technical" span="Stack" />

      <Summary summary={cargo_features.summary}/>
    </div>
  );
}

export default Cargo;
