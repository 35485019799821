import React from "react";
import { Box } from "@mui/material";
import { useRef, useEffect } from "react";
import useOnScreen from "../../helpers/isIntersectingHook";
import "./style.css";

type Props = {
  list: any[];
};

function ProjectFeatures(props: Props) {
  const serviceOfficeLine: any = useRef();
  const isVisableserviceOfficeLine = useOnScreen(serviceOfficeLine);

  useEffect(() => {
    const handleScroll = () => {
      serviceOfficeLine.current.style.opacity = 1;

      serviceOfficeLine.current.style.width = 90 + "%";
    };

    if (isVisableserviceOfficeLine) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisableserviceOfficeLine]);

  return (
    <Box className="gap">
      <h2 className="h2">
        Project <span className="headingspan">Features</span>
        <div className="lineRight" ref={serviceOfficeLine}></div>
      </h2>
      <div className="container-pf">
        {props.list.map((item: any) => (
          <div className="item-pf">
            <div className="heading-pf">
              <div className="circle-pf" />
              <div className="title-pf">{item.title}</div>
            </div>

            <div className="paragraph-pf">{item.paragraph}</div>
          </div>
        ))}
      </div>
    </Box>
  );
}

export default ProjectFeatures;
