import ModernBanner from "../../components/banner/ModernBanner";
import { crm_features } from "../../helpers/crm-features";
import Overview from "../../components/overview/Overview";
import ProjectFeatures from "../../components/project-features/ProjectFeatures";
import Steps from "../../components/steps/Steps";

type Props = {};

function CRM(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ModernBanner title="Target Group" span="CRM" imgSrc="/CRM22.png" />
      <Overview overview={crm_features.overview} />
      <ProjectFeatures list={crm_features.items} />
      <Steps steps={crm_features.steps} title="Technical" span="Stack" />
    </div>
  );
}

export default CRM;
