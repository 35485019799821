export const projects = [
    {
        id: 1,
        title: 'Target Group CRM',
        image: '/CRM.png',
        link: '/projects/crm'
    },
    {
        id: 2,
        title: 'Medical Patient Portal',
        image: '/MedicalPatientPortal.png',
        link: '/projects/medical'
    },
    {
        id: 3,
        title: 'Patient Appointment Scheduler',
        image: '/Scheduler.png',
        link: '/projects/scheduler'
    },
    {
        id: 4,
        title: 'Book Store',
        image: '/BookStore.png',
        link: '/projects/bookstore'
    },
    {
        id: 5,
        title: 'MoveCargo',
        image: '/Cargo.png',
        link: '/projects/cargo'
    },
    {
        id: 6,
        title: 'Modeliks',
        image: '/Modeliks.png',
        link: '/projects/modeliks'
    }
]