import ModernBanner from "../../components/banner/ModernBanner";
import { modelix_features } from "../../helpers/modelix-features";
import Overview from "../../components/overview/Overview";
import ProjectFeatures from "../../components/project-features/ProjectFeatures";
import Summary from "../../components/summary/Summary";
import Steps from "../../components/steps/Steps";

type Props = {};

function Modelix(props: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ModernBanner title="Modeliks" imgSrc="/Modeliks2.png" imgWidth='90%'/>
      <Overview overview={modelix_features.overview} />
      <ProjectFeatures list={modelix_features.items} />

      <Steps steps={modelix_features.steps} title="Technical" span="Stack"/>

      <Summary
        summary={modelix_features.summary}
        tools={modelix_features.tools}
      />
    </div>
  );
}

export default Modelix;
