export const modelix_features = {
  overview: `Modeliks provides an advanced yet user-friendly platform for startups and SMEs to craft fundable business and financial plans. The software is designed to simplify the intricate process of business planning, allowing even those with no experience to create professional pitch deck and comprehensive plans that appeal to investors.`,
  items: [
    {
      title: "Guided Business Planning",
      paragraph:
        "With the help of HelpHero, users are led through a step-by-step process to create compelling business plans.",
    },
    {
      title: "Investor-ready Pitch Deck Creation",
      paragraph:
        "Professionally designed templates ensure plans meet investor and lender expectations.",
    },
    {
      title: "Effortless Reporting",
      paragraph:
        "Users can manage their businesses using standardized and custom dashboards. They can benefit from plan vs. actual reporting, variance analysis, ratios, and custom KPIs. Generating detailed management reports takes more clicks.",
    },
    {
      title: "Automated Notifications",
      paragraph:
        "Integrated with a Chet bot (Hubspot) to engage users and answer queries.",
    },
    {
      title: "Finance Modeling",
      paragraph:
        "The Calculated Driver-based Financing model stands out as a key feature, allowing for intricate financial planning.",
    },
    {
      title: "Educational Content",
      paragraph: "Embedded YouTube instructions help users at each step.",
    },
  ],
  steps: [
    {
      title: "Frontend",
      circleType: 'full',
      paragraph: "ReactJS",
    },
    {
      title: "Backend",
      circleType: 'empty',
      paragraph: "Node.js with Restify.js",
    },
    {
      title: "Database",
      circleType: 'empty',
      paragraph: "MSSQL, MongoDB",
    },
    {
      title: "Integrations",
      circleType: 'empty',
      paragraph: "Impact, Paddle Payment, Hubspot Chat bot, Clarity, Google Analytics, SendGrid mail, Puppeteer for PDF creation, Chart.js, YouTube, and HelpHero for guided tour",
    },
    {
      title: "Infrastructure",
      circleType: 'empty',
      paragraph: "Hosted on AWS EC2 with custom cache management",
    },
    {
      title: 'Challenges',
      circleType: 'empty',
      paragraph: "Integrating a diverse set of tools and platforms to create a seamless user experience. Specifically, the Calculated Driver-based Financing model required intricate financial knowledge merged with software capabilities",
    },
  ],
  summary: `Modeliks not only streamlines the business planning process but also ensures the quality and professionalism of the output. This approach embodies the philosophy of providing clients with a comprehensive solution that is both easy to use and produces high-quality results.`,
  tools: [
    {
      title: "Discord Bot",
      paragraph: "Notifies team members of new versions releases",
    },
    {
      title: "Deployment",
      paragraph: "Pipelines for automated deployment ensure the software is always up-to-date",
    },
  ]
};
