export const scheduler_features = {
  overview: `Building on the foundation laid by the Patient Portal, this extension delves deep into optimizing the appointment scheduling process. The core objective of this extension is to ensure that patients receive care from medical technicians best suited to their needs while maximizing the efficiency of each technician’s schedule`,
  items: [
    {
      title: "Technician-Patient Matching",
      paragraph:
        "The system uses sophisticated algorithms to match patients with technicians. It considers factors such as technician availability, proximity, patient preferences, and specific medical requirements.",
    },
    {
      title: "Dynamic Route Calculation",
      paragraph:
        "Integrated with Google Business Apps, the system generates optimal daily routes for technicians. This considers the starting point, destination and anticipates traffic conditions, ensuring punctuality and maximizing the number of patients a technician can attend to in a day.",
    },
    {
      title: "Schedule Optimization",
      paragraph:
        "This feature automates the creation of technician schedules, ensuring that appointments are clustered efficiently and minimizing transit time.",
    },
    {
      title: "Service Plan Creation",
      paragraph:
        "Post the match-making, the system crafts service plans for each patient, guided by the insurance credits available to them.",
    },
    {
      title: "Reporting Tool",
      paragraph:
        "Allows managers and administrators to gain insights into technician efficiency, patient satisfaction, and other key metrics.",
    },
  ],
  summary: `This extended phase of the project underscores the importance of continuous refinement in healthcare systems. By streamlining the scheduling process, it not only elevates patient satisfaction but also optimizes the productivity of medical professionals, ensuring they can deliver their best care to as many patients as possible`,
  steps: [
    {
      title: 'Base technologies',
      circleType: 'full',
      paragraph: 'Inherits the same technical foundation as the Patient Portal, including ReactJS for the frontend, NodeJS + Express for the backend, and MySQL as database'
    },
    {
      title: 'Third-party Integrations',
      circleType: 'empty',
      paragraph: 'Google Business Apps play a pivotal role in the optimizing routes, and other integrations from the Patient Portal, like Adobe web forms for e-signatures and email/notification systems, continue to be utilized'
    },
    {
      title: 'Challenges',
      circleType: 'empty',
      paragraph: 'The seamless integration of so many functionalities - from patient-technician matching to route optimization - required rigorous testing and refinement to ensure operational fluidity'
    }
  ],
};
