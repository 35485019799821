export const cargo_features = {
  overview: `MoveCargo revolutionizes the freight transport industry by connecting freight forwarders, organizers, and senders on a singular platform. The platform aims to simplify and optimize the process of posting and finding freight transport requests, ensuring that even users unfamiliar with transport jargon can smoothly navigate and communicate their needs.`,
  items: [
    {
      title: "User-friendly Interface",
      paragraph:
        "A unique wizard integration that guides clients through the posting process, eliminating the need for understanding complex freight terminologies.",
    },
    {
      title: "Global Accessibility",
      paragraph:
        "Once a transport request is posted, it’s made available globally to every registered user, translated seamlessly into all world languages.",
    },
    {
      title: "Automated Matching",
      paragraph:
        "Truck owners can post their available trucks and movement routes. The system then automatically searches for and suggests potential loads in proximity.",
    },
    {
      title: "Integrated Notifications",
      paragraph:
        "A comprehensive notification system that keeps users informed and updated across numerous use cases. This was one of the most challenging and intricate features to implement, ensuring users stay connected and informed.",
    }
  ],
  summary: `MoveCargo stands as a testament to the capability of merging user experience with comprehensive technical functionalities. By simplifying complex processes, it opens up the world of freight transport to a broader audience, while still catering to industry professionals. This project, much like the others, showcases the versatility and adaptability of the solutions provided.`,
  steps: [
    {
      title: "Frontend",
      circleType: 'full',
      paragraph: "ReactJS",
    },
    {
      title: "Backend",
      circleType: 'empty',
      paragraph: "NodeJS",
    },
    {
      title: "Challenges",
      circleType: 'empty',
      paragraph: "Creating an extensive notification system across numerous use cases",
    },
    {
      title: "Database",
      circleType: 'empty',
      paragraph: "MySQL",
    },
  ],
};
