export const employees =[
    {firstName:'Borko', lastName: 'Spasic', occupation: 'Technical Manager'},
    {firstName:'Ognen', lastName: 'Ognenoski', occupation: 'Sales Manager'},
    {firstName:'Aleksandra', lastName: 'Trajcevska', occupation: 'Financial Manager'},
    {firstName:'Elena', lastName: 'Ivanovska', occupation: 'Legal and Administrative Manager'},
    {firstName:'Daniel', lastName: 'Stankovski', occupation: 'Project Manager'},
    {firstName:'Jovana', lastName: 'Ivanovska', occupation: 'Project Manager'},
    {firstName:'Ruzica', lastName: 'Davcevska', occupation: 'Quality Assurance'},
    {firstName:'Ivana', lastName: 'Pekevska', occupation: 'UX / UI'},
    {firstName:'Darko', lastName: 'Ristov', occupation: 'Developement Team'},
    {firstName:'Jane', lastName: 'Bogoeski', occupation: 'Developement Team'},
    {firstName:'Viktor', lastName: 'Stamenkovski', occupation: 'Developement Team'},
    {firstName:'Gligor', lastName: 'Simonov', occupation: 'Developement Team'},
    {firstName:'Martin', lastName: 'Pecakovski', occupation: 'Developement Team'},
    {firstName:'Ana', lastName: 'Katanic', occupation: 'UX / UI'},

]