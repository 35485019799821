import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import { options } from "../../helpers/particle options";

type Props = {
  title?: string;
  span?: string;
};

function SimpleBanner(props: Props) {
  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine);
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <div className="mb-fade-top"></div>
      <div className="bannerContainer">
        <Particles options={options} init={particlesInit} className="canvas" />
        <h1 className="heading h1">
          {props.title + " "}
          <span className="headingSpan">{props.span}</span>
        </h1>
      </div>
      <div className="mb-fade"></div>
    </div>
  );
}

export default SimpleBanner;
