export const medical_features = {
  overview: `A secure portal designed for the registration of potential customers,
      enabling patients to submit medical documentation for insurance
      verification and consultation.`,
  items: [
    {
      title: "Patient Registration",
      paragraph: "Allows patients to create profiles, fill in detailed medical applications, and attach relevant documentation.",
    },
    {
      title: "Back Office Functionality",
      paragraph: "Designed for administrative use to review documentation, draft agreements, and consult with medical professionals.",
    },
    {
      title: "Automated Patient - Practitioner Matching",
      paragraph: "Optimizes efficiency and enhances the service experience for patients.",
    },
    {
      title: "Digital Signature Integration",
      paragraph: "Adobe Sign integration permits both patients and back office to digitally sign documents within the application.",
    },
    {
      title: "Document Management",
      paragraph: "The system can create patient folders in a SharePoint environment",
    },
  ],
  steps: [
    {
      title: 'Frontend',
      circleType: 'full',
      paragraph: 'ReactJS'
    },
    {
      title: 'Backend',
      circleType: 'empty',
      paragraph: 'NodeJS + Express'
    },
    {
      title: 'Other',
      circleType: 'empty',
      paragraph: 'Role-based application, login/register system, email/notification system, PDF import/export, Adobe web forms for e-signatures, Google Gmail API, deployment on AWS, and integration with Google Business apps'
    },
    {
      title: 'Database',
      circleType: 'empty',
      paragraph: 'MySQL'
    },
  ],
};
