import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import { options } from "../../helpers/particle options";

type Props = {
  title?: string;
  span?: string;
  imgSrc?: string;
  imgWidth?: string;
};

function ModernBanner(props: Props) {
  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine);
  }, []);

  return (
    <div>
      <div className="mb-fade-top"></div>
      <div className="modernBannerContainer">
        <div className="left-side">
          <div className="modernBannerTitle">
            <h1>
              <div className="strokeme">{props.title} </div>
              <span className="headingSpan strokeme-blue">{props.span}</span>
            </h1>
          </div>

          <div className="modernBannerParticles">
            <Particles
              className="particles-modern"
              options={options}
              init={particlesInit}
            />
          </div>
        </div>

        <div id="pic" className="modernBannerPic" 
          // style={{ width: props.imgWidth ? props.imgWidth : "auto" }}
          >
          <img className="modernBannerImg" src={props.imgSrc} />
        </div>
      </div>
      <div className="mb-fade"></div>
    </div>
  );
}

export default ModernBanner;
